import React from "react";
import { classNames } from "../../utils";
interface Props {
  title: string;
  description?: string | JSX.Element;
  white?: boolean;
}

const PageTitle: React.FC<Props> = ({ title, description, white }) => (
  <div className="relative py-8 px-4 text-center lg:px-8">
    <h1
      className={classNames(
        "text-4xl font-extrabold uppercase tracking-tight",
        white ? "text-white" : "text-gray-900"
      )}
    >
      {title}
    </h1>
    {typeof description === "string" ? (
      <p
        className={classNames(
          "mx-auto mt-4 max-w-3xl text-base ",
          white ? "text-gray-100" : "text-gray-500"
        )}
      >
        {description}
      </p>
    ) : (
      description
    )}
  </div>
);

export default PageTitle;
