import React from "react";
import NextHead from "next/head";
import { useRouter } from "next/router";

interface Props {
  title?: string;
  description?: string;
  thumb?: string;
}

const defaultTitle = process.env.NEXT_PUBLIC_META_DEFAULT_TITLE || "";
const defaultDescription =
  process.env.NEXT_PUBLIC_META_DEFAULT_DESCRIPTION || "";
const domain = process.env.NEXT_PUBLIC_META_DOMAIN || "";

const Head: React.FC<Props> = ({
  title = defaultTitle,
  description = defaultDescription,
  thumb,
  children,
}) => {
  const router = useRouter();
  const alternativeThumb = `${domain}${"/seo/thumb.png"}`;
  const fullUrl = `${domain}${router.asPath}`;

  return (
    <NextHead>
      <title key="title">{title}</title>
      <link rel="shortcut icon" type="image/x-icon" href="/favicon.ico" />
      <meta key="description" name="description" content={description} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link
        key="image_src"
        rel="image_src"
        href={thumb ? thumb : alternativeThumb}
      />
      <meta key="og:title" property="og:title" content={title} />
      <meta
        key="og:image"
        property="og:image"
        content={thumb ? thumb : alternativeThumb}
      />
      <meta key="og:type" property="og:type" content="website" />
      <meta key="og:url" property="og:url" content={fullUrl} />
      <meta
        key="og:description"
        property="og:description"
        content={description}
      />
      <meta
        key="twitter:card"
        name="twitter:card"
        content="summary_large_image"
      />
      <meta
        key="twitter:image"
        property="twitter:image"
        content={thumb ? thumb : alternativeThumb}
      />
      <meta
        key="twitter:description"
        property="twitter:description"
        content={description}
      />
      <meta key="twitter:title" property="twitter:title" content={title} />
       
      <meta
        name="facebook-domain-verification"
        content="hn5l1hfsk7mgw8k0y7dskp26vasy5l"
      />
      {children}
    </NextHead>
  );
};

export default Head;
