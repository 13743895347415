import { Price as IPrice } from '@wk93/socommerce-sdk';
import React from 'react';

interface Props {
  value: IPrice | number;
}

const Price: React.FC<Props> = ({ value }) => (
  <>
    {Number(
      (typeof value !== 'number' ? value.brutto : value).toFixed(2)
    ).toLocaleString('pl-PL', {
      style: 'currency',
      currency: 'PLN',
    })}
  </>
);

export default Price;
