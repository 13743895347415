import React from 'react';

interface Props {
  size?: number;
}

const Title: React.FC<Props> = ({ children, size }) => (
  <>
    <h3 className='text-2xl font-black pb-12 text-center uppercase text-black'>
      {children}
    </h3>
  </>
);

export default Title;
