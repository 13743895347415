import React from "react";
import {
  Control,
  Controller,
  ControllerRenderProps,
  FieldError,
  useWatch,
} from "react-hook-form";

interface Props {
  name: string;
  label?: string;
  control: Control<any>;
  children: (field: ControllerRenderProps, error?: FieldError) => JSX.Element;
  labelPositionByValue?: boolean;
}

const Field: React.FC<Props> = ({
  label,
  children,
  control,
  name,
  labelPositionByValue = false,
}) => {
  const labelBaseClassName =
    "absolute left-2 z-10 bg-white px-1 -top-2.5 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:top-3 peer-placeholder-shown: transition-all peer-focus:-top-2.5  peer-focus:text-sm";
  const labelErrorClassName = `${labelBaseClassName} text-red-500 peer-placeholder-shown:text-red-500 peer-focus:text-red-500`;
  const labelNormalClassName = `${labelBaseClassName} text-gray-400 peer-placeholder-shown:text-gray-400 peer-focus:text-gray-400`;

  const labelOnNullValueClassName =
    "absolute left-2 z-10 bg-white px-1 text-base top-3 transition-all";
  const labelOnFillValueClassName =
    "absolute left-2 z-10 bg-white px-1 -top-2.5 text-sm -top-2.5";

  const value = useWatch({ control, name });

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => {
        return (
          <div className={`relative mb-8`}>
            {children(field, error)}
            {label ? (
              <label
                className={
                  `${labelPositionByValue
                    ? value && value.length > 0
                      ? error
                        ? `${labelOnFillValueClassName} text-red-500`
                        : `${labelOnFillValueClassName} text-gray-400`
                      : error
                      ? `${labelOnNullValueClassName} text-red-500`
                      : `${labelOnNullValueClassName} text-gray-400`
                    : error
                    ? labelErrorClassName
                    : labelNormalClassName
                } pointer-events-none`}
              >
                {label}
              </label>
            ) : null}
            {error ? (
              <p className="text-red-500 text-xs my-1">{error.message}</p>
            ) : null}
          </div>
        );
      }}
    ></Controller>
  );
};

export default Field;
