import React, { createContext, useReducer } from 'react';
import { classNames } from '.';

type Type = 'success' | 'error' | 'info';

interface State {
  show: boolean;
  title?: string;
  description?: string | JSX.Element;
  type?: Type;
  children?: string | JSX.Element;
}

type Action = { type: 'MODAL_SET_STATE'; payload: State };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'MODAL_SET_STATE': {
      return { ...action.payload };
    }

    default: {
      return state;
    }
  }
};

const ModalContext = createContext<{
  state: State | undefined;
  dispatch: React.Dispatch<Action>;
}>({
  state: undefined,
  dispatch: () => null,
});

export const useModalContext = () => {
  const { dispatch, state } = React.useContext(ModalContext);

  return {
    state,
    showModal: ({
      title,
      description,
      type,
      show = true,
      children,
    }: {
      show?: boolean;
      title?: string;
      description?: string | JSX.Element;
      type?: Type;
      children?: string | JSX.Element;
    }) => {
      dispatch({
        type: 'MODAL_SET_STATE',
        payload: {
          show,
          title: title || '',
          description,
          type,
          children,
        },
      });
    },
  };
};

const Modal: React.FC = () => {
  const {
    state: { show, children, title, description, type },
  } = useModalContext();

  if (!show) return null;

  const icon = !type ? null : type === 'error' ? (
    <svg
      className='h-6 w-6 text-red-600'
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      stroke='currentColor'
      aria-hidden='true'
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z'
      />
    </svg>
  ) : type === 'info' ? (
    <svg
      className='h-6 w-6 text-blue-600'
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      stroke='currentColor'
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
      />
    </svg>
  ) : (
    <svg
      className='h-6 w-6 text-green-600'
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      stroke='currentColor'
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M5 13l4 4L19 7'
      />
    </svg>
  );

  return (
    <div className={`fixed z-50 inset-0 overflow-y-auto`}>
      <div className='flex items-end justify-center min-h-screen text-center sm:block sm:p-0'>
        <div className='fixed inset-0 transition-opacity' aria-hidden='true'>
          <div className='absolute inset-0 bg-gray-500 opacity-75'></div>
        </div>

        <span
          className='hidden sm:inline-block sm:align-middle sm:h-screen'
          aria-hidden='true'
        >
          &#8203;
        </span>

        <div
          className={`inline-block align-bottom mb-12 md:mb-0 bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full`}
          role='dialog'
          aria-modal='true'
          aria-labelledby='modal-headline'
        >
          <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
            <div className={classNames(icon ? 'sm:flex sm:items-start' : '')}>
              {icon ? (
                <div
                  className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full ${
                    type === 'success'
                      ? 'bg-green-100'
                      : type === 'error'
                      ? 'bg-red-100'
                      : 'bg-blue-100'
                  } sm:mx-0 sm:h-10 sm:w-10`}
                >
                  {icon}
                </div>
              ) : null}
              <div
                className={classNames(
                  'mt-3 text-center sm:mt-0 sm:text-left',
                  icon ? ' sm:ml-4' : ''
                )}
              >
                <h3
                  className='text-lg leading-6 font-medium text-gray-900'
                  id='modal-headline'
                >
                  {title}
                </h3>
                <div className='mt-2'>
                  <p className='text-sm text-gray-500'>{description}</p>
                </div>
              </div>
            </div>
          </div>
          {children ? (
            <div className='bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse'>
              {children}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const ModalProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, { show: false });

  return (
    <ModalContext.Provider value={{ state, dispatch }}>
      <Modal />
      {children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
