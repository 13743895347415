import axios from "axios";

export interface BannerElement {
  lang: string;
  href: string;
  alt: string;
  start?: string;
  end?: string;
  images: Array<{ width: number; height: number; src: string }>;
}

export interface Banner {
  id: string;
  elements: Array<BannerElement>;
}

export const getBanners = async () => {
  const banners = (
    await axios.get<{ banners: Banner[] }>(
      `${process.env.NEXT_PUBLIC_API_URL}/public/banners`,
    )
  ).data.banners;

  return banners;
};

export const getBanner = async (id: string) => {
  const banner = (
    await axios.get<{ banner: Banner }>(
      `${process.env.NEXT_PUBLIC_API_URL}/public/banners/${id}`,
    )
  ).data.banner;

  return banner;
};
