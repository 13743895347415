import { NextRouter, useRouter } from 'next/router';

const getCurrentPage = (router: NextRouter) => {
  let slug =
    typeof router.query.slug === 'string'
      ? [router.query.slug]
      : [...router.query.slug];

  const page = slug.filter((slug) => {
    const splitted = slug.split(':');
    if (splitted.length > 1) {
      return splitted[0] === 'page';
    } else {
      return false;
    }
  });

  return page.length > 0 ? Number(page[0].split(':')[1]) : 1;
};

const getAllPages = (totalPages: number) => {
  const pages = [...Array(totalPages + 1).keys()];
  pages.shift();

  return pages;
};

const usePagination = (totalPages: number, items: number) => {
  if (items % 2 === 0) {
    throw new Error('Items must be odd');
  }
  const router = useRouter();
  const currentPage = getCurrentPage(router);

  const itemOnSide = (items - 1) / 2;
  const isOnLeftSide = currentPage <= itemOnSide + 1;
  const isOnRightSide = totalPages - itemOnSide <= currentPage;
  const isOnCenter = !isOnLeftSide && !isOnRightSide;
  const space = (items - 5) / 2;

  const onChange = (value: number) => {
    let newSlug =
      typeof router.query.slug === 'string'
        ? [router.query.slug]
        : [...router.query.slug];
    newSlug = newSlug.filter((slug) => {
      const splitted = slug.split(':');

      if (splitted.length > 1) {
        return splitted[0] !== 'page';
      } else {
        return true;
      }
    });

    if (value > 1) {
      newSlug.push(`page:${value}`);
    }

    router.push(newSlug.join('/')).then(() => window.scrollTo(0, 0));
  };

  return {
    pages:
      totalPages <= items
        ? getAllPages(totalPages)
        : isOnCenter
        ? [
            1,
            '...',
            ...getAllPages(space)
              .map((i) => currentPage - i)
              .reverse(),
            currentPage,
            ...getAllPages(space).map((i) => currentPage + i),
            '...',
            totalPages,
          ]
        : isOnLeftSide
        ? [...getAllPages(items - 2), '...', totalPages]
        : [
            1,
            '...',
            ...getAllPages(items - 2).map(
              (item) => item + totalPages - (items - 2)
            ),
          ],
    currentPage,
    onChange,
  };
};

export default usePagination;
