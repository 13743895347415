import { useEffect, useRef, useState } from 'react';

const useFixedPosition = () => {
  const divRef = useRef<HTMLDivElement>();
  const [position, setPosition] = useState('static');

  useEffect(() => {
    const checkSize = (e?) => {
      if (divRef.current) {
        const doc = document.documentElement;
        const windowPosition =
          (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
        const height = divRef.current.offsetHeight;

        setPosition(windowPosition >= height ? 'fixed' : 'static');
      }
    };

    checkSize();
    window.addEventListener('resize', checkSize, true);
    window.addEventListener('scroll', checkSize, true);

    return () => {
      window.removeEventListener('resize', checkSize);
      window.removeEventListener('scroll', checkSize);
    };
  }, []);

  return { divRef, position };
};

export default useFixedPosition;
