import Image, { ImageProps } from "next/image";
import React from "react";

interface Props extends Omit<ImageProps, "alt"> {
  alt?: string;
}

const ImageBlock: React.FC<Props> = ({ children, alt = "", ...props }) => (
  <div className="pb-[7px]">
    <div className="image-block">
      <Image alt={alt} {...props} />
    </div>
  </div>
);

export default ImageBlock;
