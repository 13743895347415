import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon,
} from "@heroicons/react/outline";
import React from "react";
import Slider, { CustomArrowProps, Settings } from "react-slick";

const NextArrow: React.FC<CustomArrowProps> = ({ onClick }) => (
  <div
    onClick={(e) => {
      e.stopPropagation();
      onClick(e);
    }}
    className="absolute cursor-pointer flex lg:hidden group-hover:lg:flex z-10 right-0 top-1/2 h-12 w-12 -mt-6 bg-white items-center justify-center"
  >
    <ArrowNarrowRightIcon className="w-6 h-6" />
  </div>
);

const PrevArrow: React.FC<CustomArrowProps> = ({ onClick }) => (
  <div
    onClick={(e) => {
      e.stopPropagation();
      onClick(e);
    }}
    className="absolute flex cursor-pointer lg:hidden group-hover:lg:flex z-10 left-0 top-1/2 h-12 w-12 -mt-6 bg-white items-center justify-center"
  >
    <ArrowNarrowLeftIcon className="w-6 h-6" />
  </div>
);

const Gallery: React.FC<Settings> = ({ children, ...settings }) => {
  const localSettings: Settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    appendDots: (dots) => (
      <div className="w-full">
        <ul>{dots}</ul>
      </div>
    ),
    customPaging: (index) => {
      return <div className="h-0.5 bg-gray-300 w-full"></div>;
    },
    ...settings,
  };

  return (
    <div
      className="group"
      style={localSettings.dots ? { paddingBottom: "10px" } : {}}
    >
      <div className="relative">
        <Slider {...localSettings}>{children}</Slider>
      </div>
    </div>
  );
};

export default Gallery;
