import {
  MinusCircleIcon,
  MinusIcon,
  PlusCircleIcon,
  PlusIcon,
} from "@heroicons/react/outline";
import React, { useState } from "react";
import { classNames } from "../../utils";

interface Props {
  title: string;
  green?: boolean;
}

const FooterColumn: React.FC<Props> = ({ title, children, green = false }) => {
  const [rolled, setRolled] = useState(true);

  return (
    <>
      <div className="text-left lg:text-center">
        <h3
          className={classNames(
            "font-semibold text-sm tracking-wider uppercase p-2 px-4 flex",
            green ? "text-green-500" : "text-white",
          )}
          onClick={() => setRolled(!rolled)}
        >
          <span className="flex-1">{title}</span>
          <span className="lg:hidden">
            {rolled
              ? <PlusIcon className="h-4 w-4" />
              : <MinusIcon className="h-4 w-4" />}
          </span>
        </h3>
        <div
          className={classNames(
            rolled ? "hidden" : "block",
            "lg:block p-4 lg:p-0",
          )}
        >
          {children}
        </div>
      </div>
    </>
  );
};

export default FooterColumn;
