import { useCustomer } from "@wk93/socommerce-sdk";
import Link from "next/link";
import React from "react";
import { useForm } from "react-hook-form";
import validateEmail from "../../../utils/validateEmail";
import Checkbox from "../../form/base/Checkbox";
import Field from "../../form/Field";
// import { sendMailToSm } from "../../useSalesManago";
import Button from "../../utils/Button";
import { useModalContext } from "../../utils/Modal";
import Script from "next/script";
// import { getOrCreateContact } from "../../../utils/getResponse";
import axios from "axios";
import { classNames } from "../../utils";

interface Props {
  green?: boolean;
}

const Newsletter: React.FC<Props> = ({ green = false }) => {
  const { subscribe } = useCustomer();
  const { showModal } = useModalContext();
  const {
    control,
    handleSubmit,
    setError,
    register,
    formState: { errors, isSubmitting },
    reset,
  } = useForm({ defaultValues: { email: "", terms: false } });

  const onSubmit = handleSubmit(async ({ email, terms }) => {
    if (!terms) return setError("terms", { message: "Pole wymagane" });
    const message = validateEmail(email);
    if (message) {
      setError("email", { message });
    } else {
      const data = await subscribe(email);
      if (data === "error") {
        setError("email", { message: "Email już istnieje w naszej bazie." });
      } else {
        try {
          const campaignId = "LnxdP";
          await axios.post("/api/gr/contact", {
            id: campaignId,
            email,
          });
        } catch (error) {}
        reset();
        showModal({
          title: "Dziękujemy za zapisanie się do newslettera",
          description:
            "Na podany adres mailowy wysłaliśmy wiadomość z linkiem potwierdzającym.",
          type: "success",
          children: (
            <Button
              size="small"
              onClick={() => showModal({ show: false })}
              type="button"
            >
              Dziękuję
            </Button>
          ),
        });
      }
    }
  });

  return (
    <div className="" id="footer-newsletter">
      <div className="">
        <div className="text-center sm:max-w-lg mx-auto">
          <h2
            className={classNames(
              "text-xl font-extrabold tracking-tight uppercase",
              green ? "text-green-500" : "text-white",
            )}
          >
            Dołącz do naszego newslettera
          </h2>
        </div>
        <form onSubmit={onSubmit} className="mt-4 sm:mx-auto sm:max-w-lg">
          <div className="sm:flex">
            <div className="min-w-0 flex-1">
              <input
                className={classNames(
                  "block w-full text-sm border shadow-sm focus:outline-none  px-3 py-2",
                  green
                    ? "bg-black text-green-400 placeholder-green-500 border-green-500"
                    : "text-black placeholder-gray-500 border-transparent focus:border-transparent",
                )}
                placeholder="Podaj swój adres e-mail"
                {...register("email")}
              />
              <span className="text-xs text-white">
                {errors.email ? errors.email.message : null}
              </span>
            </div>
            <div className="mt-4 sm:mt-0">
              <button
                disabled={isSubmitting}
                type="submit"
                className={classNames(
                  "block w-full border border-transparent px-3 py-2 bg-black text-sm font-medium  shadow  hover:text-black focus:outline-none focus:ring-2 sm:px-10",
                  green ? "text-green-500 border-green-500 hover:bg-green-500" : "text-white border-white hover:bg-white",
                )}
              >
                {isSubmitting ? "Wczytywanie" : "Dołącz"}
              </button>
            </div>
          </div>

          <Field name="terms" control={control}>
            {(field, error) => (
              <Checkbox
                {...field}
                error={error}
                className={classNames(
                  "text-xs mt-4",
                  green ? "text-green-500" : "text-white",
                )}
                label={
                  <>
                    Wyrażam dobrowolną zgodę na przetwarzanie podanych przeze
                    mnie danych osobowych przez gunsandtuxedos.co.uk
                    prowadzącego działalność pod firmą GUNS&TUXEDOS SPÓŁKA
                    AKCYJNA, ul. Mińska 25C/U-5 03-808 Warszawa, NIP: 1132983000
                    w celach marketingowych, w tym w celu realizacji usługi
                    wysyłania Newslettera na podany przeze mnie adres e-mail.
                    Więcej informacji w{" "}
                    <Link href="/polityka_prywatnosci" className="underline">
                      polityce prywatności
                    </Link>
                    .
                  </>
                }
              />
            )}
          </Field>
        </form>
      </div>
    </div>
  );
};

export default Newsletter;
