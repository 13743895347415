import { ArrowUpIcon } from '@heroicons/react/outline';
import React, { useEffect, useState } from 'react';

const BackToTop: React.FC = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const checkSize = (e?) => {
      const doc = document.documentElement;
      const windowPosition =
        (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

      setShow(windowPosition > 64);
    };

    checkSize();
    window.addEventListener('resize', checkSize, true);
    window.addEventListener('scroll', checkSize, true);
  }, []);

  return show ? (
    <div
      onClick={() => {
        scrollTo(0, 0);
      }}
      className='cursor-pointer fixed bg-black h-12 w-12 z-20 bottom-4 right-4 lg:right-8 flex items-center justify-center'
    >
      <ArrowUpIcon className='w-6 h-6 text-white' />
    </div>
  ) : null;
};

export default BackToTop;
