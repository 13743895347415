import "./app.css";
import "./preset.css";
import "../../node_modules/slick-carousel/slick/slick.css";
import "../../node_modules/slick-carousel/slick/slick-theme.css";
import { AppProps } from "next/app";
import { SocommerceProvider } from "@wk93/socommerce-sdk";
import Footer from "../components/layout/footer/Footer";
import Header from "../components/layout/header/Header";
import ModalProvider from "../components/utils/Modal";
import BackToTop from "../components/gnt/BackToTop";
import RouterWrapper from "../components/layout/wrappers/RouterWrapper";
import Head from "../components/layout/seo/Head";
import { TimeContentProvider } from "../components/layout/homepage/TimeCotnent";
import Script from "next/script";

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <meta
          name="google-site-verification"
          content="IuVC7Tq1yXzX9zaTzTGxkfrmBT5JwdOJRAmR4qzYjJU"
        />
      </Head>
      <Script id="gr-chat">
        {`  var _grChatConfig = {urlParams:"c=28965c52a581635e951cc22099aaeeb66e89008391e5af736ad32b5d67",iuv:"v1"}
            // Custom configuration goes here
            !function(a,e){Array.isArray(window.__GrChatData__)?window.__GrChatData__.push(e):window.__GrChatData__=[e];
            var r=document.createElement("script");r.defer=!0;var t=document.getElementsByTagName("script")[0];
            t.parentNode.insertBefore(r,t),r.src="https://i.getresponse.chat/embedded_chat.js"}(0,_grChatConfig);
			`}
      </Script>
      <Script id="gtm">
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-WJ25Z96');`}
      </Script>
      <Script id="get-response-analytics">
        {`(function(m, o, n, t, e, r, _){
          m['__GetResponseAnalyticsObject'] = e;m[e] = m[e] || function() {(m[e].q = m[e].q || []).push(arguments)};
          r = o.createElement(n);_ = o.getElementsByTagName(n)[0];r.async = 1;r.src = t;r.setAttribute('crossorigin', 'use-credentials');_.parentNode .insertBefore(r, _);
      })(window, document, 'script', 'https://ga.getresponse.com/script/f14c0666-2043-4fb7-bd34-6d2017493abc/ga.js', 'GrTracking');`}
      </Script>
      <SocommerceProvider
        url={`${process.env.NEXT_PUBLIC_API_URL}/public`}
        currency="PLN"
      >
        <TimeContentProvider after={new Date(1667343600 * 1000)}>
          <ModalProvider>
            <RouterWrapper>
              <Header />
              <Component {...pageProps} />
              <Footer />
              <BackToTop />
            </RouterWrapper>
          </ModalProvider>
        </TimeContentProvider>
      </SocommerceProvider>
    </>
  );
}

export default MyApp;
