import React from "react";
import { FieldError } from "react-hook-form";
import { classNames } from "../../utils";

interface Props extends Omit<React.HTMLProps<HTMLInputElement>, "label"> {
  error?: FieldError;
  label: string | JSX.Element;
}

const Checkbox = React.forwardRef<any, Props>(
  ({ error, label, ...field }, ref) => {
    return (
      <label className={classNames(field.className, "block")}>
        <input
          type="checkbox"
          {...field}
          className={`form-checkbox w-4 h-4 rounded`}
        />
        <span className="ml-3">{label}</span>
        {error ? <span>{error.message}</span> : null}
      </label>
    );
  }
);

Checkbox.displayName = "Checkbox";

export default Checkbox;
