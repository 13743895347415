import { useRouter } from 'next/dist/client/router';
import React, { useEffect, useState } from 'react';
import { useReorder } from '@wk93/socommerce-sdk';
import Loading from '../../utils/Loading';

const RouterWrapper: React.FC = ({ children }) => {
  const router = useRouter();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleRouteChange = (url, { shallow }) => {
      setLoading(true);
    };

    const handleRouteChangeComplete = (url, { shallow }) => {
      setLoading(false);
    };

    router.events.on('routeChangeStart', handleRouteChange);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);

  return (
    <>
      {loading ? <Loading className='' absolute /> : null}
      {children}
    </>
  );
};

export default RouterWrapper;
