import { ChevronRightIcon, HomeIcon } from '@heroicons/react/solid';
import { Breadcrumb } from '@wk93/socommerce-sdk';
import Container from './Container';
import Link from 'next/link';
import { classNames } from '../../utils';

interface Props {
  items: Breadcrumb[];
  white?: boolean;
}

const Breadcrumbs: React.FC<Props> = ({ items, white }) => {
  return (
    <Container>
      <nav className='flex py-3' aria-label='Breadcrumb'>
        <ol role='list' className='flex items-center space-x-2'>
          <li>
            <div>
              <Link
                href='/'
                className={
                  white ? `text-white` : `text-gray-400 hover:text-gray-500`
                }>

                <HomeIcon
                  className='flex-shrink-0 h-4 w-4'
                  aria-hidden='true'
                />

              </Link>
            </div>
          </li>
          {items.map((item) => (
            <li key={item.link.slug}>
              <div className='flex items-center'>
                <ChevronRightIcon
                  className={classNames(
                    'flex-shrink-0 h-4 w-4',
                    white ? 'text-white' : 'text-gray-400'
                  )}
                  aria-hidden='true'
                />
                <a
                  href={item.link.slug}
                  className={classNames(
                    'ml-2 text-xs font-medium ',
                    white ? 'text-white' : 'text-gray-500 hover:text-gray-700'
                  )}
                >
                  {item.name}
                </a>
              </div>
            </li>
          ))}
        </ol>
      </nav>
    </Container>
  );
};

export default Breadcrumbs;
