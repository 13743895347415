import { Fragment, useEffect, useState } from "react";
import { Dialog, Popover, Transition } from "@headlessui/react";
import {
  DeviceMobileIcon,
  HeartIcon,
  MenuIcon,
  QuestionMarkCircleIcon,
  SearchIcon,
  ShoppingCartIcon,
  UserIcon,
  XIcon,
} from "@heroicons/react/outline";
import { useCart } from "@wk93/socommerce-sdk";
import Image from "next/image";
import Container from "../utils/Container";
import Link from "next/link";
import SearchInput from "./SearchInput";
import useFixedPosition from "./useFixedPosition";
import { classNames } from "../../utils";
import { useRouter } from "next/router";
import TimeContent from "../homepage/TimeCotnent";
import { BannerElement, getBanner } from "../../../utils/getBanners";
import { isAfter, isWithinInterval, startOfDay } from "date-fns";
import Countdown from "../../gnt/Countdown";
import Logo from "../Logo";

const n = {
  pages: [
    {
      name: "GT STYLE",
      href: "/tech-line",
      image: "/gnt/head/tech.jpeg",
      subitems: [
        {
          name: "Kurtki i płaszcze",
          desc:
            "Na wiosenne deszcze i zimową zamieć. Na promenadę w Cannes i na płaskowyż Nazca. Zawsze elegancko. Zawsze GT style.",
          href: "/tl-kurtki-i-plaszcze",
        },
        {
          name: "Koszule",
          desc:
            "Zakasaj rękawy albo stwórz idealne tło dla jednej z naszych marynarek. Business casual, moda czy wieczorowa elegancja to chleb powszedni koszul Guns&Tuxedos.",
          href: "/tl-koszule",
        },
        {
          name: "T-shirts",
          desc:
            "Zacznijmy od podstaw: szeroki wybór zapewni Ci pole do popisu. Minimalistyczne druki i tematyczne wzory subtelnie podkreślą Twoje własne ja.",
          href: "/tl-t-shirts",
        },
        {
          name: "Spodnie",
          desc:
            "Five pockets? Bojówki? Joggery? Wieczorowy city glam czy coś na akcję specjalną? Sprawdź! Spodnie to nasza pasja.",
          href: "/tl-spodnie",
        },
        {
          name: "Marynarki",
          desc:
            "Prawdziwy fundament męskiej szafy. Znajdziesz tu coś na każdą okazję. Na ryby, grzyby, jacht, polowanie i... operację specjalną.",
          href: "/tl-marynarki",
        },
        {
          name: "Kamizelki",
          desc:
            "Kuloodporne mamy dopiero w planach, ale te które już mamy w ofercie podkreślą każdą stylizację w stylu GT.",
          href: "/tl-kamizelki",
        },
        {
          name: "Bluzy",
          desc:
            "Poranny jogging? Wieczorny business meeting? Sportowa elegancja? Hoodie albo vneck, na suwak lub na guziki. Mamy coś dla Ciebie na każdą okazje:)",
          href: "/tl-bluzy",
        },
        {
          name: "Swetry i kardigany",
          desc:
            "Po upalnym dniu i po nartach. Zawsze ciepłe i eleganckie. Zadasz szyku w każdym kurorcie!",
          href: "/tl-swetry-i-kardigany",
        },
        {
          name: "Obuwie",
          desc:
            "Pokaż sznyt albo rześką formę: mamy coś na wieczorny outing i poranny jogging. Idź w klasykę albo wrzuć wyższy bieg w naszych skokach.",
          href: "/tl-obuwie",
        },
        {
          name: "Akcesoria",
          desc:
            "Nie jest to szpej jednostki komandosów ale... znajdziesz tu szereg przydatnych akcesoriów. Niektóre da się wykorzystać taktycznie",
          href: "/tl-akcesoria",
        },
      ],
    },
    /*
    {
      name: "Klasyka",
      href: "/klasyka",
      image: "/gnt/head/klasyka.jpeg",
      subitems: [
        {
          name: "Garnitury",
          desc:
            "Dla jednych pancerz dla innych kwintesencja męskiej elegancji. Choć obecnie preferujemy luźniejsze outfity ... stara miłość nie rdzewieje:) Przetestuj full formal a'la GT!",
          href: "/k-garnitury",
        },
        {
          name: "Koszule",
          desc:
            "Zakasaj rękawy albo stwórz idealne tło dla jednej z naszych marynarek. Business casual, moda czy wieczorowa elegancja to chleb powszedni koszul Guns&Tuxedos.",
          href: "/k-koszule",
        },
        {
          name: "Obuwie",
          desc:
            "Pokaż sznyt albo rześką formę: mamy coś na wieczorny outing i poranny jogging. Idź w klasykę albo wrzuć wyższy bieg w naszych skokach.",
          href: "/k-obuwie",
        },
        {
          name: "Kamizelki",
          desc:
            "Kuloodporne mamy dopiero w planach, ale te które już mamy w ofercie podkreślą każdą stylizację w stylu GT.",
          href: "/k-kamizelki",
        },
        {
          name: "Akcesoria",
          desc:
            "Nie jest to szpej jednostki komandosów ale... znajdziesz tu szereg przydatnych akcesoriów. Niektóre da się wykorzystać taktycznie",
          href: "/k-akcesoria",
        },
      ],
    },
    */
  ],
};

const pala = {
  name: "By Pala",
  href: "/by-pala",
  image: "/gnt/head/pala.jpeg",
  subitems: [
    {
      name: "Spodnie",
      desc:
        "Five pockets? Bojówki? Joggery? Wieczorowy city glam czy coś na akcję specjalną? Sprawdź! Spodnie to nasza pasja.",
      href: "/bp-spodnie",
    },
    {
      name: "Marynarki",
      desc:
        "Prawdziwy fundament męskiej szafy. Znajdziesz tu coś na każdą okazję. Na ryby, grzyby, jacht, polowanie i... operację specjalną.",
      href: "/bp-marynarki",
    },
    {
      name: "Bluzy",
      desc:
        "Poranny jogging? Wieczorny business meeting? Sportowa elegancja? Hoodie albo vneck, na suwak lub na guziki. Mamy coś dla Ciebie na każdą okazje:)",
      href: "/bp-bluzy",
    },
    {
      name: "Obuwie",
      desc:
        "Pokaż sznyt albo rześką formę: mamy coś na wieczorny outing i poranny jogging. Idź w klasykę albo wrzuć wyższy bieg w naszych skokach.",
      href: "/bp-obuwie",
    },
    {
      name: "T-shirts",
      desc:
        "Zacznijmy od podstaw: szeroki wybór zapewni Ci pole do popisu. Minimalistyczne druki i tematyczne wzory subtelnie podkreślą Twoje własne ja.",
      href: "/bp-t-shirts",
    },
  ],
};

const Header: React.FC = () => {
  const [navigation, setNavigation] = useState(n);
  const [loaded, setLoaded] = useState(false);
  const [elements, setElements] = useState([] as BannerElement[]);
  const [mobileIndex, setMobileIndex] = useState(-1);
  const { divRef, position } = useFixedPosition();
  const { cart, isLoading } = useCart();
  const [open, setOpen] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const router = useRouter();
  const green = router.asPath === "/";
  //const green = false;
  const belkaVisible = router.asPath !== "/" &&
    router.pathname !== "/hot-deals";

  const escFunction = (e) => {
    if (e.metaKey && e.key === "k") {
      setShowSearch(!showSearch);
    }
  };

  useEffect(() => {
    if (document) {
      document.addEventListener("keydown", escFunction, false);
    }
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const banner = await getBanner("63b16bef5d6af15c1f4b1a6d");
      setElements(
        banner.elements.filter((element) => element.lang === "pl")
          .filter((element) => {
            if (element.start && element.end) {
              return isWithinInterval(new Date(), {
                start: new Date(element.start),
                end: new Date(element.end),
              });
            }
            return true;
          }),
      );
      setLoaded(true);
    };
    fetch();
  }, []);

  return router.asPath !== "/order"
    ? (
      <>
        <div ref={divRef}>
          {belkaVisible && elements.length > 0
            ? (
              <div>
                <div className="hidden lg:block">
                  <Image
                    width={elements[0].images[1].width}
                    height={elements[0].images[1].height}
                    alt={elements[0].alt}
                    layout="responsive"
                    src={elements[0].images[1].src}
                  />
                </div>
                <div className="block lg:hidden">
                  <Image
                    width={elements[0].images[0].width}
                    height={elements[0].images[0].height}
                    alt="Promocja"
                    layout="responsive"
                    src={elements[0].images[0].src}
                  />
                </div>
              </div>
            )
            : null}

          <div
            className={classNames(
              "hidden bg-black p-8 py-2 text-xs font-thin lg:flex",
              green ? "text-white" : "text-white",
            )}
          >
            <div className="flex-1">
              <div className="flex items-center space-x-4">
                <span className="flex items-center space-x-1">
                  <QuestionMarkCircleIcon className="h-4 w-4" />
                  <span>Masz pytania?</span>
                </span>
                <span className="flex items-center space-x-1">
                  <DeviceMobileIcon className="h-4 w-4" />
                  <span>+48 883 777 883</span>
                </span>
              </div>
            </div>
            <div>
              <span className="flex items-center space-x-2">
                <span>Dostawa do: UE</span>
                <img
                  src="https://flagicons.lipis.dev/flags/4x3/eu.svg"
                  className="h-3"
                />
              </span>
            </div>
          </div>
        </div>
        <div className={position === "fixed" ? "h-16" : "hidden"}></div>
        <div
          className={classNames(
            position,
            "top-0 left-0 right-0 z-40 uppercase",
          )}
        >
          {showSearch
            ? <SearchInput onClose={() => setShowSearch(false)} />
            : null}

          {/* Mobile menu */}
          <Transition.Root show={open} as={Fragment}>
            <Dialog
              as="div"
              className="fixed inset-0 z-40 flex lg:hidden"
              onClose={setOpen}
            >
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <div
                  className={classNames(
                    "relative flex w-full flex-col overflow-y-auto shadow-xl",
                    mobileIndex > -1 ? "bg-white" : "bg-gray-100",
                  )}
                >
                  <div className="flex bg-white px-4 pt-5 pb-5">
                    <button
                      type="button"
                      className="-m-2 inline-flex items-center justify-center p-2 text-gray-400"
                      onClick={() => setOpen(false)}
                    >
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  <div className="border-t border-gray-200">
                    <div className="divide-y bg-white px-4">
                      {mobileIndex > -1
                        ? (
                          <>
                            <span
                              className="-mx-2 block cursor-pointer p-2 py-4 text-left font-medium uppercase text-gray-900"
                              onClick={() => setMobileIndex(-1)}
                            >
                              Wróć
                            </span>
                            <a
                              href={navigation.pages[mobileIndex].href}
                              className="-mx-2 block cursor-pointer p-2 py-4 text-left font-medium uppercase text-gray-900"
                            >
                              Pokaż wszystko
                            </a>
                          </>
                        )
                        : null}

                      {(mobileIndex > -1
                        ? navigation.pages[mobileIndex].subitems
                        : navigation.pages).map((page: any, index: number) =>
                          page.subitems
                            ? (
                              <span
                                key={page.name}
                                className="-mx-2 block cursor-pointer p-2 py-4 text-left font-medium uppercase text-gray-900"
                                onClick={() => setMobileIndex(index)}
                              >
                                {page.name}
                              </span>
                            )
                            : (
                              <a
                                href={page.href}
                                key={page.name}
                                className="-mx-2 block p-2 py-4 text-left font-medium uppercase text-gray-900"
                              >
                                {page.name}
                              </a>
                            )
                        )}

                      {mobileIndex === -1
                        ? (
                          <>
                            <a
                              href={"/black-capsule"}
                              className="-mx-2 block p-2 py-4 text-left font-medium uppercase text-gray-900"
                            >
                              Black Capsule
                            </a>
                            <a
                              href={"/nowosci"}
                              className="-mx-2 block p-2 py-4 text-left font-medium uppercase text-gray-900"
                            >
                              Nowości
                            </a>
                            <a
                              href={"/sale"}
                              className="-mx-2 block p-2 py-4 text-left font-medium uppercase text-gray-900"
                            >
                              Wyprzedaż
                            </a>
                          </>
                        )
                        : null}
                    </div>
                    <div className="divide-y px-4">
                      {mobileIndex === -1
                        ? (
                          <>
                            <span
                              className="-mx-2 block p-2 py-4 text-left font-medium  text-gray-900"
                              onClick={() => {
                                setOpen(false);
                                setShowSearch(true);
                              }}
                            >
                              <span className="flex items-center space-x-1">
                                <SearchIcon className="h-4 w-4" />
                                <span>Szukaj</span>
                              </span>
                            </span>
                            <a
                              href={"/customer"}
                              className="-mx-2 block p-2 py-4 text-left font-medium  text-gray-900"
                            >
                              <span className="flex items-center space-x-1">
                                <UserIcon className="h-4 w-4" />
                                <span>Moje konto</span>
                              </span>
                            </a>
                            <a
                              href={"/kontakt"}
                              className="-mx-2 block p-2 py-4 text-left font-medium text-gray-900"
                            >
                              <span className="flex items-center space-x-1">
                                <QuestionMarkCircleIcon className="h-4 w-4" />
                                <span>Masz pytania?</span>
                              </span>
                            </a>
                            <a
                              href={"/"}
                              className="-mx-2 block p-2 py-4 text-left font-medium text-gray-900"
                            >
                              <span className="flex items-center space-x-1">
                                <DeviceMobileIcon className="h-4 w-4" />
                                <span>+48 883 777 883</span>
                              </span>
                            </a>
                          </>
                        )
                        : null}
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </Dialog>
          </Transition.Root>

          <header
            className={classNames("relative", green ? "bg-black" : "bg-white")}
          >
            <Container>
              <div>
                <div className="flex h-16 items-center justify-between">
                  <div className="flex flex-1 items-center lg:hidden">
                    <button
                      type="button"
                      className={classNames(
                        "-ml-2 p-2",
                        green
                          ? "text-white hover:text-gray-300"
                          : "text-gray-700 hover:text-black bg-white ",
                      )}
                      onClick={() => setOpen(true)}
                    >
                      <MenuIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                    <span
                      onClick={() => setShowSearch(true)}
                      className="ml-6 hidden cursor-pointer p-2 text-gray-400 hover:text-gray-500 lg:block"
                    >
                      <SearchIcon className="h-6 w-6" aria-hidden="true" />
                    </span>
                  </div>

                  {/* Flyout menus */}
                  <Popover.Group className="hidden lg:block lg:flex-1 lg:self-stretch">
                    <div className="flex h-full space-x-4 xl:space-x-8">
                      {navigation.pages.map((page, index) => (
                        <div
                          className="flex items-center hover-trigger"
                          key={`${page.name}-${index}`}
                        >
                          <a
                            key={page.name}
                            href={page.href}
                            className={classNames(
                              "flex items-center text-sm font-medium",
                              page.name === "Wyprzedaż"
                                ? "text-red-500 hover:text-red-600"
                                : green
                                ? "text-white hover:text-gray-200"
                                : "text-gray-700 hover:text-gray-800",
                            )}
                          >
                            {page.name}
                          </a>
                          {navigation.pages[index].subitems
                            ? (
                              <div
                                className={classNames(
                                  position === "fixed"
                                    ? "top-full"
                                    : "top-full",
                                  `absolute left-0 right-0 z-50 border-t border-gray-200 bg-white text-black shadow-lg hover-target`,
                                )}
                              >
                                <Container>
                                  <div className=" mx-auto">
                                    <div className="flex items-center">
                                      <div className="grid flex-1 grid-cols-4 gap-8 py-4">
                                        {navigation.pages[index].subitems.map(
                                          (item, index) => (
                                            <a key={index} href={item.href}>
                                              <div className="py-4 text-left text-black">
                                                <div className="mb-2 font-bold uppercase">
                                                  {item.name}
                                                </div>
                                                <div className="text-justify text-xs font-thin normal-case">
                                                  {item.desc ? item.desc : ""}
                                                </div>
                                              </div>
                                            </a>
                                          ),
                                        )}
                                      </div>
                                      <div className="py-8 pl-8 text-2xl text-black">
                                        <Image
                                          alt="img"
                                          src={page.image}
                                          width={500}
                                          height={500}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Container>
                              </div>
                            )
                            : null}
                        </div>
                      ))}
                      <div className="flex items-center hover-trigger">
                        <a
                          href={"/black-capsule"}
                          className={classNames(
                            "flex items-center text-sm font-medium",
                            green
                              ? "text-white hover:text-gray-200"
                              : "text-gray-700 hover:text-gray-800",
                          )}
                        >
                          Black Capsule
                        </a>
                      </div>
                      <div className="flex items-center hover-trigger">
                        <a
                          href={"/nowosci"}
                          className={classNames(
                            "flex items-center text-sm font-medium",
                            green
                              ? "text-gray-100 hover:text-white"
                              : "text-green-500 hover:text-green-600",
                          )}
                        >
                          Nowości
                        </a>
                      </div>
                      <div className="flex items-center hover-trigger">
                        <a
                          href={"/sale"}
                          className={classNames(
                            "flex items-center text-sm font-medium",
                            "text-red-500 hover:text-red-600",
                          )}
                        >
                          Wyprzedaż
                        </a>
                      </div>
                    </div>
                  </Popover.Group>

                  <div className="block ">
                    {/* Logo */}
                    <Link href="/" className="flex">
                      {green
                        ? (
                          <Logo
                            variant="text"
                            className="h-4 text-white"
                          />
                        )
                        : (
                          <Image
                            alt="logo"
                            height={15}
                            width={150}
                            src="/gnt/logo-txt.jpg"
                          />
                        )}
                    </Link>
                  </div>

                  <div className="flex flex-1 items-center justify-end">
                    {/* Wishlist */}
                    <Link
                      href="/wishlist"
                      className={classNames(
                        "hidden p-2  lg:block",
                        green
                          ? "text-white hover:text-gray-300"
                          : "text-gray-700",
                      )}
                    >
                      <HeartIcon className="h-6 w-6" aria-hidden="true" />
                    </Link>

                    {/* Search */}
                    <span
                      onClick={() => setShowSearch(true)}
                      className={classNames(
                        "ml-2 hidden cursor-pointer p-2 lg:block",
                        green
                          ? "text-white hover:text-gray-300"
                          : "text-gray-700 hover:text-black",
                      )}
                    >
                      <SearchIcon className="h-6 w-6" aria-hidden="true" />
                    </span>

                    {/* Account */}
                    <Link
                      href="/customer"
                      className={classNames(
                        "hidden p-2 lg:ml-2 lg:block",
                        green
                          ? "text-white hover:text-gray-300"
                          : "text-gray-700 hover:text-black",
                      )}
                    >
                      <UserIcon className="h-6 w-6" aria-hidden="true" />
                    </Link>

                    {/* Cart */}
                    <Link
                      href="/cart"
                      className={classNames(
                        "block p-2  pr-0 lg:ml-2",
                        green
                          ? "text-white hover:text-gray-300"
                          : "text-gray-700 hover:text-black",
                      )}
                    >
                      <ShoppingCartIcon
                        className="h-6 w-6"
                        aria-hidden="true"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </Container>
          </header>
        </div>
      </>
    )
    : null;
};

export default Header;
