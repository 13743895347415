import React from 'react';
import Title from './Title';

interface Props {
  title?: string;
}

const Container: React.FC<Props> = ({ title, children }) => {
  return (
    <div className={`px-4 sm:px-6 lg:px-8 ${title ? '' : ''}`}>
      {title ? <Title size={2}>{title}</Title> : null}
      {children}
    </div>
  );
};

export default Container;
