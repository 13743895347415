import axios from "axios";
import React, { createContext, useEffect, useReducer, useState } from "react";
import { isAfter, startOfDay } from "date-fns";
import Loading from "../../utils/Loading";

interface Props {
  date?: Date;
  before: React.ReactNode;
  after: React.ReactNode;
}

interface State {
  date: Date;
  after?: Date;
}

type Action = { type: "TIME_SET_DATE"; payload: State };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "TIME_SET_DATE": {
      return { ...action.payload };
    }
    default: {
      return state;
    }
  }
};

const TimeContext = createContext<{
  state: State | undefined;
  dispatch: React.Dispatch<Action>;
}>({ state: undefined, dispatch: () => null });

export const useTimeContext = () => {
  const { state } = React.useContext(TimeContext);

  return state;
};

interface TimeContentProviderProps {
  after?: Date;
}

export const TimeContentProvider: React.FC<TimeContentProviderProps> = ({
  children,
  after,
}) => {
  const [state, dispatch] = useReducer(reducer, null);

  useEffect(() => {
    const fetch = async () => {
      const serverDate = new Date();

      dispatch({ type: "TIME_SET_DATE", payload: { date: serverDate, after } });
    };

    fetch();
  }, [after]);

  return (
    <TimeContext.Provider value={{ state, dispatch }}>
      {children}
    </TimeContext.Provider>
  );
};

const TimeContent: React.FC<Props> = ({ date, after, before }) => {
  const [loading, setLoading] = useState(false);
  const [afterDate, setAfter] = useState(false);
  const state = useTimeContext();

  useEffect(() => {
    if (state && state.date) {
      setAfter(isAfter(state.date, startOfDay(date || state.after)));
      setLoading(false);
    }
  }, [state, date]);

  return (
    <>
      {loading ? (
        <Loading className="text-gray-600" />
      ) : (
        <>{afterDate ? after : before}</>
      )}
    </>
  );
};

export default TimeContent;
