import React from 'react';
import LoadingIcon from './LoadingIcon';

interface Props {
  fullWidth?: boolean;
  size?: 'normal' | 'large' | 'small';
  isLoading?: boolean;
  span?: boolean;
  inverse?: boolean;
}

const Button: React.FC<
  Props &
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >
> = ({
  isLoading = false,
  fullWidth = false,
  size = 'normal',
  children,
  span,
  inverse = false,
  ...props
}) => {
  return span ? (
    <span
      className={`inline-flex uppercase items-center justify-center transition ease-in-out duration-150 ${
        inverse
          ? isLoading
            ? 'disabled cursor-not-allowed bg-black  text-gray-300 uppercase'
            : 'bg-gray-100 text-black hover:bg-black hover:text-white'
          : isLoading
          ? 'disabled cursor-not-allowed bg-black  text-gray-300'
          : 'bg-black text-white hover:text-black hover:bg-gray-100 uppercase'
      } border border-transparent ${
        size === 'large'
          ? 'py-3 text-lg'
          : size === 'normal'
          ? 'py-3 text-base'
          : 'text-sm font-thin py-2 px-3'
      } ${fullWidth ? 'w-full' : ''} px-8 font-normal `}
      {...props}
    >
      {isLoading ? (
        <LoadingIcon className='-ml-1 mr-3 h-5 w-5 text-white' />
      ) : null}
      <span className='block'>{children}</span>
    </span>
  ) : (
    <button
      disabled={isLoading || props.disabled}
      className={`inline-flex items-center justify-center transition ease-in-out duration-150 ${
        inverse
          ? isLoading || props.disabled
            ? 'disabled cursor-not-allowed bg-black  text-gray-300 uppercase'
            : 'bg-gray-100 text-black hover:bg-black hover:text-white'
          : isLoading || props.disabled
          ? 'disabled cursor-not-allowed bg-black text-gray-300 uppercase'
          : 'bg-black text-white hover:text-black hover:bg-gray-100 uppercase'
      } border border-transparent ${
        size === 'large'
          ? 'py-3 text-lg'
          : size === 'normal'
          ? 'py-3 text-base'
          : 'text-sm font-thin py-2 px-3'
      } ${fullWidth ? 'w-full' : ''} px-8 font-normal `}
      {...props}
    >
      {isLoading ? (
        <LoadingIcon className='-ml-1 mr-3 h-5 w-5 text-white' />
      ) : null}
      <span className='block'>{children}</span>
    </button>
  );
};

export default Button;
