import debounce from 'lodash.debounce';
import { useCallback, useState } from 'react';
import { Collection as ICollection, search } from '@wk93/socommerce-sdk';

const useSearch = () => {
  const [loading, setLoading] = useState(false);
  const [collection, setCollection] = useState<ICollection | null>(null);
  const [value, setValue] = useState('');

  const debouncedSave = useCallback(
    debounce(async (newValue) => {
      setLoading(true);

      if (newValue.length > 0) {
        const searchResult = await search(
          `${process.env.NEXT_PUBLIC_API_URL}/public`,
          {
            searchValue: encodeURIComponent(newValue),
          }
        );

        if (searchResult.results.count === 0) {
          setCollection(null);
        } else {
          setCollection({
            name: `Wyniki wyszukiwania hihi`,
            description: '',
            media: [],
            breadcrumbs: [],
            ...searchResult.results,
            filters: [],
          });
        }
      } else {
        setCollection(null);
      }
      setLoading(false);
    }, 500),
    []
  );

  const onChange = (newValue: string) => {
    setValue(newValue);
    debouncedSave(newValue);
  };

  return {
    value,
    collection,
    onChange,
    loading,
  };
};

export default useSearch;
