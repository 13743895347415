import {
  AdjustmentsIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  XIcon,
} from '@heroicons/react/outline';
import { Filter } from '@wk93/socommerce-sdk';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { classNames } from '.';
import Button from './Button';
import Link from 'next/link';
interface Props {
  filters: Filter[];
  count: number;
}

const Filters: React.FC<Props> = ({ children, filters, count }) => {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [activeTab, setActiveTab] = useState(-1);

  const getFilters = () => {
    const filtersSlug =
      typeof router.query.slug === 'string'
        ? []
        : router.query.slug.filter((value) => {
            const splitted = value.split(':');
            return splitted.length > 1 && splitted[0] === 'filters';
          });

    return filtersSlug.length > 0
      ? filtersSlug[0].split(':')[1].split(',')
      : [];
  };

  const isChecked = (code: string) => {
    const filters = getFilters();

    if (filters) {
      return filters.indexOf(code) > -1;
    }

    return false;
  };

  const onChange = (code: string) => {
    const filters = getFilters();

    setIsLoading(true);

    if (filters.indexOf(code) > -1) {
      filters.splice(filters.indexOf(code), 1);
    } else {
      filters.push(code);
    }

    const newSlug = [
      typeof router.query.slug === 'string'
        ? router.query.slug
        : router.query.slug[0],
    ];

    if (filters.length > 0) {
      newSlug.push(`filters:${filters.join(',')}`);
    }

    router.push(newSlug.join('/')).then(() => {
      window.scrollTo(0, 0);
      setIsLoading(false);
    });
  };

  return (
    <div className='border-b px-4 lg:px-8 flex justify-end text-sm divide-x bg-white'>
      {children ? (
        <span className='flex-1 flex items-center'>{children}</span>
      ) : null}
      <span className='p-4'>
        <span
          className='flex space-x-2 items-center cursor-pointer'
          onClick={() => setShowFilters(true)}
        >
          <AdjustmentsIcon className='w-4 h-4' />
          <span>
            Pokaż filtry{' '}
            {getFilters().length > 0 ? <>({getFilters().length})</> : null}
          </span>
        </span>
      </span>
      <div
        className={classNames(
          'fixed inset-0 z-40',
          showFilters ? 'block' : 'hidden'
        )}
      >
        <div
          className='absolute inset-0 bg-black bg-opacity-25'
          onClick={() => setShowFilters(false)}
        ></div>
        <div className='absolute inset-0 md:left-auto md:max-w-md bottom-20 bg-white w-full overflow-y-scroll'>
          <div className='flex items-center border-b bg-gray-50'>
            <div className='flex-1 text-xl uppercase p-5'>Filtrowanie</div>
            <div
              className={classNames(
                'px-4 underline',
                filters.length > 0 ? 'block' : 'hidden'
              )}
            >
              <Link
                href={
                  typeof router.query.slug === 'string'
                    ? router.query.slug
                    : router.query.slug[0]
                }
              >
                Wyczyść filtry
              </Link>
            </div>
            <div
              className='cursor-pointer p-5 border-l'
              onClick={() => setShowFilters(!showFilters)}
            >
              <XIcon className='w-6 h-6' />
            </div>
          </div>
          {filters.map((filter, index) => (
            <div key={filter.group.code} className='border-b'>
              <div
                className='text-left text-xl flex items-center uppercase font-sans p-4 cursor-pointer'
                onClick={() => setActiveTab(activeTab === index ? -1 : index)}
              >
                <div className='flex-1'>
                  {filter.group.name}{' '}
                  {getFilters().filter((item) =>
                    item.startsWith(filter.group.code)
                  ).length > 0 ? (
                    <>
                      (
                      {
                        getFilters().filter((item) =>
                          item.startsWith(filter.group.code)
                        ).length
                      }
                      )
                    </>
                  ) : null}
                </div>
                {activeTab === index ? (
                  <ChevronUpIcon className='h-6 w-6' />
                ) : (
                  <ChevronDownIcon className='h-6 w-6' />
                )}
              </div>
              <ul
                className={classNames(
                  'text-lg p-4',
                  index === activeTab ? 'block' : 'hidden'
                )}
              >
                {filter.items.map((item) => (
                  <div
                    className={classNames(
                      'flex items-center py-2',
                      item.available ? 'cursor-pointer' : ''
                    )}
                    key={item.code}
                    onClick={() =>
                      item.available || isChecked(item.code)
                        ? onChange(item.code)
                        : null
                    }
                  >
                    <input
                      checked={isChecked(item.code)}
                      onChange={() => {}}
                      disabled={!(item.available || isChecked(item.code))}
                      type='checkbox'
                      className='h-6 w-6 border-gray-300 text-gray-500 focus:ring-gray-500'
                    />
                    <div className='ml-4'>
                      <label
                        htmlFor='same-as-shipping'
                        className={
                          item.available
                            ? 'font-medium text-gray-900'
                            : 'text-gray-200'
                        }
                      >
                        {item.name}
                      </label>
                    </div>
                  </div>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <div className='absolute bottom-0 left-0 right-0 md:left-auto md:max-w-md md:w-full bg-white p-4'>
          <Button
            fullWidth
            isLoading={router.isFallback || isLoading}
            onClick={() => setShowFilters(!showFilters)}
          >
            Pokaż produkty {filters.length > 0 ? <>({count})</> : null}
          </Button>
        </div>
      </div>
    </div>
  );
};
export default Filters;
