import { useRouter } from 'next/dist/client/router';
import React from 'react';
import { classNames } from '../../utils';
import usePagination from './usePagination';

interface Props {
  totalPages: number;
}

const Pagination: React.FC<Props> = ({ totalPages }) => {
  const { currentPage, pages, onChange } = usePagination(totalPages, 7);

  if (totalPages < 2) {
    return null;
  }

  return (
    <nav
      className='relative z-0 inline-flex -space-x-px'
      aria-label='Pagination'
    >
      {pages.map((i) => (
        <span
          key={i}
          onClick={() => (typeof i === 'number' ? onChange(i) : null)}
          className={classNames(
            `relative cursor-pointer inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium`,
            currentPage === i ? 'bg-black border-black text-white' : ''
          )}
        >
          {i}
        </span>
      ))}
    </nav>
  );
};
export default Pagination;
