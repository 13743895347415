import { useRouter } from "next/dist/client/router";
import React, { useEffect, useRef, useState } from "react";
import { Collection as ICollection, search } from "@wk93/socommerce-sdk";
import Collection from "../../link/Collection";

import { classNames } from "../../utils";
import Filters from "../../utils/Filters";
import useSearch from "./useSearch";
import { XIcon } from "@heroicons/react/outline";
import LoadingIcon from "../../utils/LoadingIcon";
import Link from "next/link";

interface Props {
  onClose: () => void;
}

const Search: React.FC<Props> = ({ onClose }) => {
  const ref = useRef<HTMLInputElement>(null);
  const searchRef = useRef<HTMLInputElement>(null);
  const [margin, setMargin] = useState(0);
  const { value, onChange, collection, loading } = useSearch();

  const escFunction = (e) => {
    if (e.key === "Escape") {
      onClose();
    }
  };

  useEffect(() => {
    if (ref) {
      ref.current.focus();
    }

    if (document) {
      document.addEventListener("keydown", escFunction, false);
    }

    return () => document.removeEventListener("keydown", escFunction, false);
  }, []);

  useEffect(() => {
    const checkMargin = () => {
      if (searchRef && searchRef.current) {
        setMargin(searchRef.current.offsetHeight);
      }
    };

    checkMargin();
    window.addEventListener("resize", checkMargin, true);
    window.addEventListener("scroll", checkMargin, true);
    document.body.classList.add("no-overflow");

    return () => {
      document.body.classList.remove("no-overflow");
      window.removeEventListener("resize", checkMargin);
      window.removeEventListener("scroll", checkMargin);
    };
  }, []);

  return (
    <div
      className={classNames(
        "fixed inset-0 z-30",
        collection ? "bg-black" : "bg-black bg-opacity-10",
      )}
    >
      <div className="relative h-full">
        <div ref={searchRef} className="bg-white">
          <form
            className=""
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div
              className={classNames(
                "max-w-xl mx-auto relative",
                !collection ? "py-2" : "py-2",
              )}
            >
              <input
                ref={ref}
                placeholder="Szukaj..."
                className={classNames(
                  "w-full text-lg px-2 h-12 outline-none border-0  font-thin  focus:ring-0 ",
                  !collection
                    ? "border-b border-gray-200 focus:border-gray-200"
                    : "",
                )}
                type="text"
                value={value}
                onChange={(e) => onChange(e.currentTarget.value)}
              />
              {loading
                ? (
                  <LoadingIcon className="w-6 h-6 text-gray-800 absolute right-1 top-5" />
                )
                : (
                  <XIcon
                    className="w-6 h-6 text-gray-800 absolute right-1 top-5 cursor-pointer"
                    onClick={() => onClose()}
                  />
                )}
            </div>
          </form>
          {collection
            ? (
              <div className="border-t">
                <div className="border-b px-4 lg:px-8 flex justify-end text-sm divide-x bg-white">
                  <span className="flex-1 flex items-center h-12">
                    Znaleziono: {collection.count} {collection.count === 1
                      ? "produkt"
                      : collection.count > 1 && collection.count < 5
                      ? "produkty"
                      : "produktów"}
                  </span>
                </div>
              </div>
            )
            : (
              <div className="bg-white py-8 max-w-xl mx-auto text-center">
                <div className="grid grid-cols-2 text-sm">
                  <div>
                    <span className="text-xs uppercase text-center block mb-4 text-gray-400">
                      Popularne
                    </span>
                    <ul className="space-y-2 lowercase font-thin">
                      <li>
                        <Link href="/spodnie">
                          SPODNIE
                        </Link>
                      </li>
                      <li className="cursor-pointer">
                        <Link href="/kurtki-i-plaszcze">
                          KURTKI
                        </Link>
                      </li>
                      <li className="cursor-pointer">
                        <Link href="/tech-line">
                          TECH-LINE
                        </Link>
                      </li>
                      <li>
                        <Link href="/by-czapul-collection">
                          BY CZAPUL COLLECTION
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <span className="text-xs uppercase text-center block mb-4 text-gray-400">
                      Sugestie
                    </span>
                    <ul className="space-y-2 lowercase font-thin">
                      <li className="cursor-pointer">
                        <Link href="/koszule">
                          KOSZULE
                        </Link>
                      </li>
                      <li className="cursor-pointer">
                        <Link href="/t-shirts">
                          T-SHIRTS
                        </Link>
                      </li>
                      <li className="cursor-pointer">
                        <Link href="/obuwie">
                          OBUWIE
                        </Link>
                      </li>
                      <li className="cursor-pointer">
                        <Link href="/marynarki">
                          MARYNARKI
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
        </div>
        <div
          className="left-0 right-0 bottom-0 top-0 absolute overflow-scroll"
          style={{ marginTop: `${margin}px` }}
        >
          {collection
            ? (
              <Collection
                collection={collection}
                search
                querySearch={value}
                onSearchMore={() => {
                  onClose();
                }}
              />
            )
            : null}
        </div>
      </div>
    </div>
  );
};

export default Search;
